import { PageDto, RequestParams } from '@/generated/typing';
import { Flex } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { ReactNode } from 'react';
import { ListDateFilter } from './list-date-filter';
import { ListPagination } from './list-pagination';
import { ListResults } from './list-results';
import { ListSearch } from './list-search';
import { ListProvider } from './list.context';
import { ListQueryParams } from './types';
import { ListStatusFilter } from './list-status-filter';
import { QueryKey } from '@tanstack/react-query';

interface ListProps<TItem> {
    queryLabel?: string;
    columns: ColumnDef<TItem, string>[];
    queryCacheName: QueryKey;
    withSearch?: boolean;
    withStatusFilter?: boolean;
    withDateFilter?: boolean;
    query: (
        query: ListQueryParams,
        params?: RequestParams,
    ) => Promise<PageDto & { data?: TItem[] }>;
    selection?: {
        selected: string[];
        onChange: (selected: string[]) => void;
        keyProperty: keyof TItem;
    };
    actionSection?: ReactNode;
    disablePagination?: boolean;
    maxSelected?: number;
    section?: string;
}

export const List = <TItem extends { id: string }>({
    queryLabel = 'q',
    columns,
    query,
    queryCacheName,
    withSearch = false,
    withStatusFilter = false,
    withDateFilter = false,
    selection,
    actionSection,
    disablePagination = false,
    maxSelected,
    section,
}: ListProps<TItem>) => {
    return (
        <ListProvider
            query={query}
            queryCacheName={queryCacheName}
            queryLabel={queryLabel}
            section={section}
        >
            {(withSearch || actionSection || withDateFilter || withStatusFilter) && (
                <Flex pb={2} borderColor="gray.100" flexWrap={{ base: 'wrap', lg: 'nowrap' }}>
                    {withSearch && <ListSearch />}
                    <Flex flex={1} justify="flex-end" gap={2}>
                        {withDateFilter && <ListDateFilter />}
                        {withStatusFilter && <ListStatusFilter />}
                        {actionSection}
                    </Flex>
                </Flex>
            )}
            <ListResults<TItem> {...{ selection, columns, maxSelected }} />
            {!disablePagination && <ListPagination />}
        </ListProvider>
    );
};
